$(document).ready(function () {
    common();
    bannerSlider();
    productDetailSlider();
    goToTop();
    question();

    productSharing();
    blogSharing();
    bgImagesLazyload();
    lazyMap();
    owlProducts();
    owlNews();
    loadGoogleFont();
    autoGenerateRwdTableInEditor();
});

function bannerSlider() {
    var bSlider = null;
    Pace.on('done', function () {
        var $bannerSlider = $('#bannerSlider');
        if ($bannerSlider.length > 0) {

            if ($bannerSlider.find('.ms-view').length == 0) {
                setMasterSliderImageOnScreen($bannerSlider);

                try {
                    bSlider = new MasterSlider();
                    // adds Arrows navigation control to the slider.

                    bSlider.control('timebar', {
                        insertTo: '#bannerSlider'
                    });
                    bSlider.control('bullets');

                    bSlider.control('circletimer', {
                        autohide: false,
                        overVideo: true,
                        color: '#FFFFFF',
                        radius: 4,
                        stroke: 9
                    });

                    bSlider.control('arrows', {
                        autohide: true
                    });
                    bSlider.setup('bannerSlider', {
                        width: 1440, // slider standard width
                        height: 719, // slider standard height
                        // minHeight: 400,
                        start: 1,
                        space: 0,
                        layout: 'fullwidth',
                        loop: true,
                        preload: 0,
                        instantStartLayers: false,
                        autoplay: true,
                        overPause: true,
                        view: "fadeBasic"
                    });
                    // $(window).trigger('resize');
                    $('.master-skeleton-loader').remove();

                } catch (err) {
                    console.error(err);
                    removeErrorMasterSliderContainer($bannerSlider);
                }
            }


        }
    });
}

function productDetailSlider() {
    var bSlider = null;
    Pace.on('done', function () {
        var $productSlider = $('#productSlider');
        if ($productSlider.length > 0) {
            if ($productSlider.find('.ms-view').length == 0) {
                try {

                    bSlider = new MasterSlider();
    
                    bSlider.control(
                        'thumblist', {
                            autohide: false,
                            overVideo: true,
                            dir: 'h',
                            speed: 17,
                            inset: false,
                            arrows: false,
                            hover: false,
                            customClass: '',
                            align: 'bottom',
                            type: 'thumbs',
                            margin: 5,
                            width: 100,
                            height: 100,
                            space: 5,
                            fillMode: 'fill'
                        });
    
                    bSlider.setup('productSlider', {
                        width: 500,
                        height: 500,
                        space: 5,
                        view: 'fadeBasic'
                    });
                    // $(window).trigger('resize');
    
                } catch (err) {
                    console.log(err);
                    removeErrorMasterSliderContainer($productSlider);
                }
            }
           

        }
    });
}

function goToTop() {
    var $scrollToTop = $('.scrollToTop');
    $(window).scroll(function () {
        if ($scrollToTop.scrollTop() > 300) {
            $scrollToTop.fadeIn();
        } else {
            $scrollToTop.fadeOut();
        }
    });

    //Click event to scroll to top
    var $htmlRoot = $('html, body');
    $scrollToTop.click(function () {
        $htmlRoot.animate({
            scrollTop: 0
        }, 800);
        return false;
    });
}

function question() {
    var $htmlRoot = $('html, body');
    if (window.JUMP_DIR != '') {
        var $q4Elem = $("#" + window.JUMP_DIR);
        if ($q4Elem.length > 0) {
            $htmlRoot.animate({
                scrollTop: $q4Elem.offset().top
            }, 2000);

        }
    }

    var $questions =
        $('.questions');

    var $questionsTitle =
        $questions.find('li > h3');

    $questionsTitle.prepend('<i class="fa fa-caret-right" aria-hidden="true"></i> ');

    $questionsTitle.click(function (e) {

        var $expand = $(this).find('i');
        // console.log($expand);
        if ($expand.hasClass('fa-caret-right')) {
            //open
            var $answer =
                $(this).next();
            $answer.show();

            $expand.removeClass('fa-caret-right')
                .addClass('fa-caret-down');
        } else {
            //close
            var $answer =
                $(this).next();
            $answer.hide();

            $expand.removeClass('fa-caret-down')
                .addClass('fa-caret-right');
        }

    });

    var $answers =
        $questions.find('li > div');
    $answers.hide();

}

function blogSharing() {
    initJsSocial($('#blogSharing'));
}

function productSharing() {
    initJsSocial($('#productSharing'));
}

function initJsSocial($targetElem) {
    if ($targetElem.length > 0) {
        $targetElem.jsSocials({
            shares: ["facebook", "linkedin", "twitter"]
        });
    }
}

function loadGoogleFont() {
    Pace.on('done', function () {
        WebFont.load({
            timeout: 2000,
            google: {
                families: [
                    'Noto Sans TC:100,300,500,700,900&display=swap'
                    , 'Roboto&display=swap'
                ]
            }
        });
    });
}

function owlProducts() {
    Pace.on('done', function () {
        owlSlider($('.owl-products'), 5, 1, {
            autoWidth: false,
            center: true,
            responsiveRefreshRate: 0,
            responsive: {
                0: {
                    items: 1,
                },
                767: {
                    items: 4,
                }
            }
        });
    });
}


function owlNews() {
    Pace.on('done', function () {
        owlSlider($('.owl-news'), 5, 1, {
            autoWidth: false,
            center: true,
            responsiveRefreshRate: 0,
            responsive: {
                0: {
                    items: 1,
                },
                767: {
                    items: 4,
                }
            }
        });
    });
}

function owlSlider($owlSlider, items, slideBy, extendOpts) {
    console.log('owlSlider');
    if ($owlSlider.length > 0) {

        var opts = {
            loop: true,
            items: items,
            animateOut: 'fadeOut',
            animateIn: 'fadeIn',
            margin: 20,
            autoWidth:false,
            autoplay: true,
            nav: true,
            dots: true,
            slideBy: slideBy
        }

        $.extend(opts, extendOpts);


        var $owlComp = $owlSlider.owlCarousel(opts);
        var $owlImages = $owlSlider.find('img');
        var isEnableLz = false;
        $owlImages.on('inview', function (event, isInView) {
            if (isInView && isEnableLz == false) {
                isEnableLz = true;
                startLoadImages($owlImages);

            }
        });

        return $owlComp;
    }

}

function bgImagesLazyload() {
    initLazyload($('.footer'));
}

function lazyMap() {
    Pace.on('done', function () {
        initViewLazyOfIframe($('#homeMap'));
        // initViewLazyOfIframe($('#contactMap'));
        
    });
}

//第三方iniview載入機制
function initViewLazyOfIframe($targetElem) {
    var isEnable = false;
    if ($targetElem.length > 0) {
        $targetElem.on('inview', function (event, isInView) {
            if (isInView) {
                if (!isEnable) {
                    $targetElem.attr('src', $targetElem.attr('data-url'));
                    isEnable = true;
                }
            }
        });
    }
}
